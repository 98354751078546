import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128048177319'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Length": [
      "",
      "43 1/4 / 110",
      "43 3/4 / 111",
      "44 / 112",
      "44 1/2 / 113",
      "",
      "",
      "",
      ""
    ],
    "Breast": [
      "",
      "26 / 66",
      "27 1/2 / 70",
      "29 / 74",
      "30 3/4 / 78",
      "",
      "",
      "",
      ""
    ],
    "Waist": [
      "",
      "25 / 63.5",
      "26 / 66",
      "27 / 68.5",
      "28 / 71.1",
      "",
      "",
      "",
      ""
    ],
    "Hip": [
      "",
      "31 / 79",
      "32 1/2 / 83",
      "34 1/4 / 87",
      "35 3/4 / 90.8",
      "",
      "",
      "",
      ""
    ],
    "Slit": [
      "",
      "14 / 35.5",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  }

  return (
    <Layout>
      <SEO title="Shop - Oblo Dress" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify